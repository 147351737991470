import uuidv4 from "../fe-ui/utils/uuidv4";
import { BackendApiClient } from "./api-client";
import { CreateBookingDto } from "./api-client/service";

export const fetchBookingCreate = (params: { recaptchaToken: string } & CreateBookingDto) => {
  const { recaptchaToken, ...body } = params;

  return BackendApiClient.BookingService.bookingControllerCreateBooking(
    { body, language: "en" },
    {
      headers: {
        "Idempotency-Key": uuidv4(),
        "x-recaptcha-token": recaptchaToken
      }
    }
  );
};
