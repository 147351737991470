import axios from "axios";
import { serviceOptions } from "./service";
import * as AggregationApiClient from "./service";
import { enqueueSnackbar } from "notistack";
import { getLocale } from "../../fe-ui/utils/getLocale";
import { env } from "../../fe-ui/utils/env";
// import { request } from "../../fe-ui/utils/request";

const instance = axios.create({
  baseURL: env("AGGREGATION_API_ROOT", "https://aggregation.seaya.az"),
});

instance.interceptors.request.use((config) => {
  if (typeof window !== "undefined") {
    const language = getLocale();
    if (language) {
      try {
        config.params = {
          ...config.params,
          language, // getRecoil(userSelectedLanguageIdAtom),
        };
      } catch {
        return config;
      }
    }
  }
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.statusCode === 400 && typeof window !== "undefined") {
      enqueueSnackbar(error.message, {
        autoHideDuration: 5000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || "Something went wrong")
);

serviceOptions.axios = instance;

instance.interceptors.request.use((config) => {
  //
  return config;
});

export { AggregationApiClient };
