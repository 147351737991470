import { IUnitGroupListItem } from "../@types/unitGroup";
import { AggregationApiClient } from "./aggregation-client";

const mockResponse: IUnitGroupListItem[] = [
  // {
  //   id: "1",
  //   title: "Mock Unit Group 1",
  //   description: "This is a mock unit group",
  //   link: "/unit/1"
  // },
  // {
  //   id: "2",
  //   title: "Mock Unit Group 2",
  //   description: "This is another mock unit group",
  //   link: "/unit/2"
  // }
];

export const fetchUnitGroupListByAttributes = ({
  lastId,
  perPage = 12,
  hotelIds,
  attributes = "",
  hotelAttributes = "",
  sortOrder = "asc",
  sortByField,
}: {
  perPage?: 4 | 12 | 24;
  lastId?: string;
  hotelIds?: string;
  attributes?: string;
  hotelAttributes?: string;
  sortOrder?: string;
  sortByField?: string;
}): Promise<IUnitGroupListItem[]> => {
  if (process.env.NEXT_PUBLIC_APP_ENV === "mock") {
    return Promise.resolve(mockResponse);
  }

  const payload: any = {};

  if (lastId) {
    payload.lastId = lastId;
  }
  if (perPage) {
    payload.perPage = perPage;
  }
  if (hotelIds) {
    payload.hotelIds = hotelIds;
  }
  if (attributes) {
    payload.attributes = attributes;
  }
  if (sortOrder) {
    payload.sortOrder = sortOrder;
  }
  if (sortByField) {
    payload.sortByField = sortByField;
  }
  if (hotelAttributes) {
    payload.hotelAttributes = hotelAttributes;
  }

  return AggregationApiClient.HotelUnitGroupService.hotelUnitGroupControllerFindUnitGroups({
    language: "en",
    perPage: 12,
    sortByField: "price",
    ...payload,
  })
    .then((items) => {
      const unitGroups: IUnitGroupListItem[] = items.map((f) => ({
        ...f,
        description: f.description?.replace(/(<([^>]+)>)/gi, "") || "",
        shortDescription: f.shortDescription?.replace(/(<([^>]+)>)/gi, "") || "",
        link: `/unit/${f.id}`,
      }));
      return unitGroups;
    })
    .catch(() => []);
};
