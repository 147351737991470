import { BackendApiClient } from "./api-client";

export const fetchSingleUnitGroupById = (id: string, { language } = { language: "en" }) => {
  // if (process.env.NEXT_PUBLIC_APP_ENV === "mock") {
  //   request.withMock({ url, response: propertyUnitDefaultMock });
  // }
  return BackendApiClient.HotelUnitGroupService.hotelUnitGroupControllerGetUnitGroup({
    id,
    language,
  })
    .then((h) => {
      return {
        ...h,
        link: `/unit/${h.id}`,
        hotelLink: `/hotel/${h.hotelId}`,
        ratePlanId: h.ratePlanId || "",
        description: h.description || "",
        shortDescription: h.shortDescription || "",
      };
    })
    .catch(() => null);
};
