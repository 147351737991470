// import { propertiesDefaultMock } from "../_mock/api/propertiesDefaultMock";
import { BackendApiClient } from "./api-client";
import { HotelDto } from "../api/api-client/service";

export const fetchProperties = (
  props = {
    language: "en",
    perPage: 24,
  }
) => {
  // if (process.env.NEXT_PUBLIC_APP_ENV === "mock") {
  //   return Promise.resolve(propertiesDefaultMock);
  // }

  return (
    BackendApiClient.HotelsService.hotelsControllerFindAll({
      perPage: props.perPage,
      page: 0,
      language: props.language,
    })
      // data transform
      .then((items) => {
        const mappedItems: HotelDto[] = items.map((item) => {
          return {
            ...item,
            description: item?.description?.replace(/(<([^>]+)>)/gi, "") || "",
            shortDescription: item?.shortDescription?.replace(/(<([^>]+)>)/gi, "") || "",
            media: item.media,
            image: item.media[0]?.url,
            link: `/hotel/${item.id}`,
            pricePerNight: item.pricePerNight || 0,
            promotionLabels: item.promotionLabels,
            attributes: item.attributes,
            contactNumber: item.contactNumber,
          };
        });
        return mappedItems;
      })
      .catch((_err) => {
        return [] as HotelDto[];
      })
  );
};
