import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { fetchBookingCreate } from "./fetchBookingCreate";
import { fetchOffers } from "./fetchOffers";
import { fetchSingleUnitGroupById } from "./fetchSingleUnitGroupById";
import { fetchUnitGroupListByAttributes } from "./fetchUnitGroupListByAttributes";
import { fetchProperties } from "./fetchProperties";
import { fetchAllUnitGroupAttributes } from "./fetchAllUnitGroupAttributes";
import { fetchAllPropertyAttributes } from "./fetchAllPropertyAttributes";
import { fetchAllHotelsPromotionLabels } from "./fetchAllHotelsPromotionLabels";

import { HotelDto } from "../api/api-client/service";

import { IUnitGroupListItem } from "../@types/unitGroup";
import { fetchFutureOffers } from "./fetchFutureOffers";
import { fetchUnitGroupPromotionLabels } from "./fetchUnitGroupPromotionLabels";
import type { MutableRefObject } from "react";

/**
 *
 */
export const useListProperties = () => {
  return useQuery<HotelDto[]>(["properties"], () => fetchProperties());
};

/**
 *
 * @returns
 */
export const useGetAllPropertyAttributes = () => {
  return useQuery(["all-hotel-attributes"], () => fetchAllPropertyAttributes(), {
    cacheTime: 99999,
  });
};

/**
 *
 * @returns
 */
export const useGetAllUnitAttributes = () => {
  return useQuery(["all-unit-group-attributes"], () => fetchAllUnitGroupAttributes(), {
    cacheTime: 99999,
  });
};

/**
 *
 * @returns
 */
export const useGetAllHotelsPromotionLabels = () => {
  return useQuery(["all-hotels-promotion-labels"], () => fetchAllHotelsPromotionLabels(), {
    cacheTime: 99999,
  });
};

/**
 *
 * @returns
 */
export const useGetUnitGroupPromotionLabels = () => {
  return useQuery(["all-unit-group-promotion-labels"], () => fetchUnitGroupPromotionLabels(), {
    cacheTime: 99999,
  });
};

/**
 *
 * @param id
 * @returns
 */
export const useGetSingleUnitGroupById = (id: string) => {
  return useQuery(["unit-group-single", id], () => fetchSingleUnitGroupById(id), {
    enabled: !!id,
  });
};

/**
 *
 * @param p
 * @returns
 */
export const useListUnitGroup = (p?: Parameters<typeof fetchUnitGroupListByAttributes>[0]) => {
  return useQuery<IUnitGroupListItem[]>(["unit-groups"], () => fetchUnitGroupListByAttributes(p || {}));
};

/**
 *
 * @param hotelId
 * @param attributes
 * @returns
 */
export const useInfiniteListUnitGroup = (hotelIds?: string, attributes?: string, sortOrder?: string) => {
  return useInfiniteQuery<IUnitGroupListItem[]>(
    ["infinite-unit-groups", hotelIds, attributes, sortOrder],
    ({ pageParam: lastId }) => {
      return fetchUnitGroupListByAttributes({
        lastId,
        hotelIds,
        attributes,
        sortOrder,
      });
    },
    {
      getNextPageParam: (lastPage, _pages) => {
        return lastPage.length >= 12 && lastPage[lastPage.length - 1]?.id;
      },
    }
  );
};

/**
 *
 */
export const useListOffers = (p: Parameters<typeof fetchOffers>[0]) => {
  return useQuery(["offers-list", JSON.stringify(p)], () => fetchOffers(p));
};

/**
 *
 * @param p
 * @returns
 */
export const useInfiniteListOffers = (
  p: Parameters<typeof fetchOffers>[0] & {
    pagePath?: string;
    currentPerPage: MutableRefObject<number>;
  }
) => {
  const { currentPerPage, ...params } = p;

  return useInfiniteQuery(
    ["infinte-offers-list", JSON.stringify(params)],
    ({ pageParam }) => {
      return fetchOffers({
        ...params,
        lastId: pageParam ? pageParam : undefined,
        perPage: currentPerPage.current,
      });
    },
    {
      getNextPageParam: (lastPage, _pages) => {
        return lastPage.length > 0 && lastPage[lastPage.length - 1]?.unitGroupId;
      },
    }
  );
};

/**
 *
 * @param p
 * @returns
 */

export const useInfiniteListFutureOffers = (
  p: Parameters<typeof fetchOffers>[0] & {
    pagePath?: string;
    currentPerPage: MutableRefObject<number>;
  }
) => {
  const { currentPerPage, ...params } = p;

  return useInfiniteQuery(
    ["infinte-future-offers-list", JSON.stringify(params)],
    ({ pageParam }) => {
      return fetchFutureOffers({
        ...params,
        lastId: pageParam ? pageParam : undefined,
        perPage: currentPerPage.current,
      });
    },
    {
      getNextPageParam: (lastPage, _pages) => {
        return lastPage.length > 0 && lastPage[lastPage.length - 1]?.unitGroupId;
      },
    }
  );
};

/**
 *
 * @returns
 */
export const useCreateBooking = () => {
  return useMutation({
    // TODO: ?
    mutationKey: "booking-create-uuid-goes-here",
    mutationFn: (params: Parameters<typeof fetchBookingCreate>[0]) => fetchBookingCreate(params),
  });
};
