import { BackendApiClient } from "./api-client";

export const fetchAllHotelsPromotionLabels = () => {
  return BackendApiClient.HotelsService.hotelsControllerFindPromotionLabels({
    perPage: 99,
    page: 0,
    language: "en"
  })
    .then(items => {
      const mappedItems = items;
      return mappedItems;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
};
