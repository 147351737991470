import { BackendApiClient } from "./api-client";
import { OfferDto } from "./api-client/service";

type Props = {
  lastId?: string;
  arrivalDate: string;
  departureDate: string;
  hotelIds?: string;
  attributes?: string;
  adults: number;
  children?: number;
  sortOrder?: string;
  minPrice?: number;
  maxPrice?: number;
  perPage?: number;
};

export const fetchOffers = (p: Props) => {
  // if (process.env.NEXT_PUBLIC_APP_ENV === "mock") {
  //   request.withMock({ url, response: offersDefaultMock });
  // }

  const payload: any = {};

  if (p.attributes) {
    payload.attributes = p.attributes;
  }

  if (p.hotelIds) {
    payload.hotelIds = p.hotelIds;
  }

  return BackendApiClient.OffersService.offersControllerSearchOffers({
    perPage: 12,
    ...p,
    ...payload,
    language: "en",
    arrivalDate: p.arrivalDate,
    departureDate: p.departureDate,
    sortByField: "price",
  })
    .then((data) => {
      return data.offers.map((offer) => {
        return {
          ...offer,
          unitGroup: {
            ...offer.unitGroup,
            description: offer.unitGroup.description?.replace(/(<([^>]+)>)/gi, "") || "",
            shortDescription: offer.unitGroup.shortDescription?.replace(/(<([^>]+)>)/gi, "") || "",
          },
        };
      });
    })
    .catch(() => [] as OfferDto[]);
};
